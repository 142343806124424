<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31">
      <section class="main-content">
        <div class="section post-wrapper" v-if="event != null">
          <div :class="['post', event.cancelled ? 'post-cancelled':'']" v-if="event.status">
            <span class="tag tag--danger tag-cancelled" v-if="event.cancelled">Evento cancelado</span>
            <div class="post__header">
              <div class="post__author">
                <div v-if="event.autor?.profilePicture" class="user__image user__image--sm">
                  <img referrerpolicy="no-referrer" :src="event.autor.profilePicture">
                </div>
                <div class="user__image" v-else>
                  <img src="@/assets/images/avatar_default.png">
                </div>
                <div class="user__data">
                  <h5>{{event.autor?.fullName}}</h5>
                  <p class="caption text-alt">Organizador</p>
                </div>
              </div>
              <div class="post__options" v-if="event.status && !event.cancelled">
                <DropdownMenu>
                  <template #button>
                    <button class="btn btn--icon btn--circle">
                      <img src="@/assets/images/icons/ico_dots--bl.svg">
                    </button>
                  </template>
                  <template #options>
                    <router-link v-if="$store.state.user.id == event.autor.id" class="dropdown__option" :to="{ name: 'EditEvent', params: { id: event.id } }">
                      Editar
                    </router-link>
                    <a class="dropdown__option" href="#" @click.prevent="displayShareDialog(event)">
                      Compartir evento
                    </a>
                    <a class="dropdown__option danger" v-if="$store.state.user.id == event.autor.id && !event.cancelled" href="#" @click="showPreview = true">
                      Cancelar evento
                    </a>
                    <a class="dropdown__option danger" v-if="$store.state.user.id == event.autor.id || $store.state.user.role_id == 1" href="#" @click="showDelete = true">
                      Eliminar
                    </a>
                  </template>
                </DropdownMenu>
              </div>
            </div>
            <div class="post__main-content">
              <div class="post__causes-and-areas">
                <img src="@/assets/images/icons/ico_book--gray.svg">
                <div class="post__causes">
                  <template v-for="cause in event.causes" :key="cause.id">
                    <span class="caption post__cause">{{cause.name}}</span>
                  </template>
                  <template v-for="area in event.areas" :key="area.id">
                    <span class="caption post__cause">{{area.name}}</span>
                  </template>
                </div>
              </div>
              <div class="post__title">
                <h3>{{event.title}}</h3>
                <br>
                <p class="caption text-alt">{{ event.participants }} participante(s)</p>
                <br>
                <p class="post__description">
                  {{ event.description }}
                </p>
              </div>
              <div class="post__tags">
                <template v-for="tag in event.tags" :key="tag.id">
                  <span class="tag tag--primary post__tag">
                    {{tag.name}}
                  </span>
                </template>
              </div>
              <div class="post__cover-image" v-if="event.imageUrl">
                <img referrerpolicy="no-referrer" :src="event.imageUrl">
              </div>
              <br>
              <p class="post__content" v-html="event.information">
              </p>
              <div class="post__url" v-if="event.urlEvent">
                <h5 class="post__url-title">Enlace externo</h5>
                <div class="post__url-info" v-if="!event.cancelled">
                  <img class="post__url-icon" src="@/assets/images/icons/ico_external-link--black.svg">
                  <a :href="event.urlEvent" target="_blank">{{ event.urlEvent }}</a>
                </div>
                <div v-else class="post__url-info">
                  <img class="post__url-icon" src="@/assets/images/icons/ico_external-link--black.svg">
                  <a target="_blank">{{ event.urlEvent }}</a>
                </div>
              </div>
              <div class="post__download" v-if="event.fileUrl && !event.cancelled">
                <a class="btn btn--primary" :href="event.fileUrl" target="_blank">
                  <img class="icon" src="@/assets/images/icons/ico_download--white.svg"> Descargar archivo adjunto
                </a>
              </div>
              <h5 class="text-alt">Más información</h5>
              <br>
              <div class="post__meta">
                <table class="table-list">
                  <tr>
                    <td><p>Fecha del evento:</p></td>
                    <td class="text-right">{{ this.$moment(event.fecha).format('DD') }} de {{this.$moment(event.fecha).format('MMMM')}} del {{this.$moment(event.fecha).format('YYYY')}} </td>
                  </tr>
                  <tr>
                    <td><p>Hora de inicio:</p></td>
                    <td class="text-right">{{ this.$moment(event.hora).format('HH:mm') }}</td>
                  </tr>
                  <template v-if="event.onSite">
                    <tr>
                      <td><p>Region:</p></td>
                      <td class="text-right">{{ event.region }}</td>
                    </tr>
                    <tr>
                      <td><p>Comuna:</p></td>
                      <td class="text-right">{{ event.comuna }}</td>
                    </tr>
                    <tr>
                      <td><p>Dirección / Ubicación:</p></td>
                      <td class="text-right">{{ event.address }}</td>
                    </tr>
                  </template>
                  <tr v-else>
                    <template v-if="!event.cancelled">
                      <td><p>Enlace:</p></td>
                      <td class="text-right" v-if="$store.state.user.id && this.futureEvent">
                        <a target="_blank" :href="event.virtualUrl">Ir al enlace</a>
                      </td>
                      <td class="text-right" v-else>
                        <a href="#" @click.prevent="notSignedIn()">Ir al enlace</a>
                      </td>
                    </template>
                    <template v-else>
                      <td><p>Enlace:</p></td>
                      <td class="text-right">
                        <a target="_blank">Ir al enlace</a>
                      </td>
                    </template>
                  </tr>
                  <tr v-if="event.email">
                    <td><p>Correo de contacto:</p></td>
                    <td class="text-right">{{ event.email }}</td>
                  </tr>
                  <tr v-if="event.mobile">
                    <td><p>Teléfono de contacto:</p></td>
                    <td class="text-right">{{ event.mobile }}</td>
                  </tr>
                  <tr v-if="event.firstUrl">
                    <td><p>Facebook:</p></td>
                    <td v-if="!event.cancelled" class="text-right"><a :href="event.firstUrl" target="_blank">Ir a Facebook</a></td>
                    <td v-else class="text-right"><a target="_blank">Ir a Facebook</a></td>
                  </tr>
                  <tr v-if="event.secondUrl">
                    <td><p>Twitter:</p></td>
                    <td v-if="!event.cancelled" class="text-right"><a :href="`https://www.twitter.com/${ event.secondUrl }`" target="_blank">Ir a Twitter</a></td>
                    <td v-else class="text-right"><a target="_blank">Ir a Twitter</a></td>
                  </tr>
                  <tr v-if="event.thirdUrl">
                    <td><p>Instagram:</p></td>
                    <td v-if="!event.cancelled" class="text-right"><a :href="`http://www.instagram.com/${ event.thirdUrl }`" target="_blank">Ir a instagram</a></td>
                    <td v-else class="text-right"><a target="_blank">Ir a instagram</a></td>
                  </tr>
                </table>
              </div>
              <div class="post-footer">
                <div class="form__actions" v-if="$store.state.user.id && this.futureEvent && $store.state.user.id != event.autor.id">
                  <template v-if="event.participating == 1">
                    <button class="btn btn--primary" @click="signUp(3)" :disabled="savingParticipation">&check; Asistiré</button>
                    <button class="btn btn--primary btn--outline" @click="signUp(2)" :disabled="savingParticipation">Me interesa</button>
                  </template>
                  <template v-else-if="event.participating == 2">
                    <button class="btn btn--primary btn--outline" @click="signUp(1)" :disabled="savingParticipation">Asistiré</button>
                    <button class="btn btn--primary" @click="signUp(3)" :disabled="savingParticipation">&check; Me interesa</button>
                  </template>
                  <template v-else>
                    <button class="btn btn--primary btn--outline" @click="signUp(1)" :disabled="savingParticipation">Marcar asistencia</button>
                    <button class="btn btn--primary btn--outline" @click="signUp(2)" :disabled="savingParticipation">Me interesa</button>
                  </template>
                </div>
                <div class="form__actions" v-else-if="$store.state.user.id">
                  <template v-if="event.participating == 1">
                    <button class="btn btn--primary" :disabled="true">&check; Asistiré</button>
                    <button class="btn btn--primary" :disabled="true">Me interesa</button>
                  </template>
                  <template v-else-if="event.participating == 2">
                    <button class="btn btn--primary" :disabled="true">Asistiré</button>
                    <button class="btn btn--primary" :disabled="true">&check; Me interesa</button>
                  </template>
                  <template v-else>
                    <button class="btn btn--primary" :disabled="true">Marcar asistencia</button>
                    <button class="btn btn--primary" :disabled="true">Me interesa</button>
                  </template>
                </div>
                <div class="form__actions" v-else>
                  <button class="btn btn--primary" @click.prevent="notSignedIn()">Marcar asistencia</button>
                  <button class="btn btn--primary" @click.prevent="notSignedIn()">Me interesa</button>
                </div>
                <div class="post__interactions-side">
                  <div class="post__interaction">
                    <button class="btn btn--icon btn--circle" v-if="!event.cancelled">
                      <img class="icon" src="@/assets/images/icons/ico_share--orange.svg" @click.prevent="displayShareDialog(event)">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="post-list__end" v-else>
            <h4>Este evento fue eliminado por su autor</h4>
            <img src="@/assets/images/illustrations/ill_checklist.png" alt="En la imagen un listado de tareas completado">
          </div>
        </div>
      </section>
    </div>
  </div>
  <share-dialog v-if="showShareDialog && event" :sharing="sharing" @close="showShareDialog = false"></share-dialog>
  <modal-dialog v-if="showPreview && event" @close="showPreview = false">
    <template #title><h3 class="text-center">¿Estas seguro de cancelar el evento?</h3></template>
    <template #footer>
      <div class="form__actions form__actions--center">
        <button class="btn btn--primary btn--outline" type="button" @click.prevent="showPreview = false" :disabled="submitting">Cancelar</button>
        <button class="btn btn--primary" type="button" :disabled="submitting" @click.prevent="cancelEvent(this.event.id)">Confirmar</button>
      </div>
    </template>
  </modal-dialog>
  <modal-dialog v-if="showDelete && event" @close="showDelete = false">
    <template #title><h3 class="text-center">¿Estas seguro de eliminar el evento?</h3></template>
    <template #footer>
      <div class="form__actions form__actions--center">
        <button class="btn btn--primary btn--outline" type="button" @click.prevent="showDelete = false" :disabled="submitting">Cancelar</button>
        <button class="btn btn--primary" type="button" :disabled="submitting" @click.prevent="deleteEvent(this.event.id)">Confirmar</button>
      </div>
    </template>
  </modal-dialog>
  <register-dialog v-if="showRegisterDialog" @close="showRegisterDialog = false"></register-dialog>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import ShareDialog from '@/components/ShareDialog.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import RegisterDialog from '@/components/RegisterDialog.vue'

export default {
  name: 'Event',
  components: { 
    SideNav,
    DropdownMenu,
    ShareDialog,
    ModalDialog,
    RegisterDialog,
  },
  data () {
    return {
      event: null,
      loading: true,
      showShareDialog: false,
      sharing: {},
      savingParticipation: false,
      futureEvent: false,
      showPreview: false,
      showDelete: false,
      showRegisterDialog: false
    }
  },
  async created() {
    await this.loadData()
  },
  methods: {
    async loadData(){
      this.loading = true
      const res = (await this.$axios.get(`Event/event/${this.$route.params.id}`))
      if (res.status == 200) {
        this.event = res.data
        document.title = `Evento ${this.event.title} - Dorapp`
        this.futureEvent = ((this.$moment().isSameOrBefore(this.$moment(this.event.fecha), 'day') && this.$moment().isSameOrBefore(this.$moment(this.event.fecha), 'month') && this.$moment().isSameOrBefore(this.$moment(this.event.fecha), 'year')) && this.event.status && !this.event.cancelled)
        //this.futureEvent = (this.$moment().isSameOrBefore(this.$moment(this.event.fecha)) && this.event.status && !this.event.cancelled)
        this.loading = false
      }
      else {
        this.$toast.error(res.data)
        this.$router.push({name: 'Home'})
      }
    },
    displayShareDialog(el){
      this.sharing.url = `${window.location.origin}/evento/${this.$route.params.id}`
      this.sharing.title = el.title || el.description
      //this.sharing.description = el.description
      //this.sharing.quote = 'quote'
      this.sharing.hashtags = el.tags.map(n => n.name).join(',')
      this.sharing.twitterUser = el.secondUrl
      this.showShareDialog = true
    },
    async signUp(code){
      this.savingParticipation = true
      const res = await this.$axios.post(`Event/assistance/signUp`, { id: this.$route.params.id, code: code})
      if(res.status==200)
      {
        this.event.participating = code
        if(res.data != 0){
          this.event.participants = res.data + 1
        }else if(res.data == 0 && this.event.participants > 1){
          this.event.participants = this.event.participants - 1
        }
        
        this.$toast.success('Tu participación ha sido registrada')
      }
      this.savingParticipation = false
    },
    async deleteEvent(id){
      const res = await this.$axios.post('Event/delete-event', {event_id: id})
      if(res.status == 200){
        this.$toast.success('El evento ha sido eliminado exitosamente')
        this.$router.push('/eventos')
      }
      else
        this.$toast.error(res.data)
    },
    async cancelEvent(id){
      const res = await this.$axios.post('Event/cancel-event', {event_id: id})
      if(res.status == 200){
        this.$toast.success('El evento ha sido cancelado exitosamente')
        this.$router.push('/eventos')
      }
      else
        this.$toast.error(res.data)
    },
    closeRegisterModal() {
      this.showRegisterDialog = false
    },
    notSignedIn() {
      this.showRegisterDialog = true
    }
  },
  watch:{
    "$route": async function(route) {
      if(route.name == 'Event')
        await this.loadData()
    }
  }

}
</script>